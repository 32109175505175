import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import loadingGif from '../../assets/img/loading.gif'
import { useNavigate } from 'react-router-dom';

interface ConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  text: string;
  type: string;
  isLoading?: boolean;
  titleText: string;
  onClick?: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, onHide, text, type, isLoading = false, titleText = 'Configuración', onClick = () => { } }) => {
  const navigate = useNavigate();
  
  const handleContinue = () => {
      navigate('/vinculacion');
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{type} {titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {titleText !== 'Estimación' && titleText !== 'Eliminar archivo' ? (
          <>
            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> :
              <p>{text}</p>}
          </>
        ) : (
          <>
            {text}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {titleText === 'Estimación' &&
          <>
            <Button variant="light" onClick={onHide} disabled={isLoading}>
              Cancelar
            </Button>
            <Button variant="dark" onClick={onClick}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Continuar'}
            </Button>
          </>
        }
        {titleText === 'Eliminar archivo' &&
          <>
            <Button variant="light" onClick={onHide} disabled={isLoading}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={onClick}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Eliminar'}
            </Button>
          </>
        }
        {titleText === 'Base de conocimiento en uso' &&
          <>
            <Button variant="danger" onClick={onClick}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Eliminar'}
            </Button>
          </>
        }
        {titleText === 'Aviso' && (
          <>
            <Button variant="dark" onClick={onHide} disabled={isLoading}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={ () => {handleContinue(); onClick(); }}>
              {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Continuar'}
            </Button>
          </>
        )}
        {titleText === 'Correcto' && (
          <>
            <Button variant="primary" onClick={handleContinue}>
              Continuar
            </Button>
          </>
        )}
        {titleText !== 'Estimación' && titleText !== 'Eliminar archivo' && titleText !== 'Aviso' && titleText !== 'Correcto' &&(
          <Button variant="dark" onClick={onHide} disabled={isLoading}>
            Cerrar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;