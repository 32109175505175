import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade } from '@mui/material';

interface GeneratedLinkupModalProps {
    show: boolean;
    onHide: () => void;
    profiles: any[];
    titleText: string;
    isLoading: boolean;
}

const GeneratedLinkupModal: React.FC<GeneratedLinkupModalProps> = ({ show, onHide, profiles, titleText, isLoading }) => {

    const [vinculado, setVinculado] = useState(false);

    // Función que cambia el estado cuando se hace clic en el botón
    const handleClick = () => {
        setVinculado(true);
    };

    function SampleNextArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#0047ba" }}
                onClick={onClick}
            />
        );
    }

    // Componente personalizado para la flecha "Prev"
    function SamplePrevArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#0047ba" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return (
        <Modal show={show} onHide={onHide} size='lg' className='custom-close'>
            <Modal.Header closeButton >
                <Modal.Title>{titleText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Slider {...settings}>
                    {profiles.map((empresa, index) => (
                        <div key={index} className="profile-slide">
                            <h3>Perfil {index + 1}</h3>
                            <p><strong>Razón Social:</strong> {empresa.razon_social}</p>
                            <p><strong>Actividad:</strong> {empresa.actividad}</p>
                            <p><strong>Producto/Servicio Principal:</strong> {empresa.principal_producto_servicios}</p>
                            <p><strong>Dirección:</strong> {empresa.direccion}</p>
                            <p><strong>Código Postal:</strong> {empresa.codigo_postal}</p>
                            <button
                                className={`btn ${vinculado ? 'btn-info' : 'btn-outline-info'}`}
                                onClick={handleClick}
                                disabled={vinculado} // Deshabilitamos el botón cuando ya esté vinculado
                            >
                                {vinculado ? (
                                    <>
                                        Vinculado ✓
                                    </>
                                ) : (
                                    'Vincular perfil'
                                )}
                            </button>
                        </div>
                    ))}
                </Slider>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={onHide} disabled={isLoading}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GeneratedLinkupModal;