import React, { useState } from 'react';
import Logo from '../../assets/img/logo_luisa.png';
import { Link, useLocation } from 'react-router-dom';
import handleLogout from '../../functions/functions';

const TopbarCoparmex = () => {
    const location = useLocation();
    const [activeButton, setActiveButton] = useState<string>(location.pathname);

    const luisa_username = localStorage.getItem('luisa_username');

    const handleSection = (section: string) => {
        setActiveButton(section);
    };

    const sections = [
        { name: 'Luisa', route: '/home' },
        { name: 'Vinculación', route: '/vinculacion' },
        { name: 'Publicidad', route: '/publicidad' },
        { name: 'Eventos', route: '/eventos' },
        { name: 'Configuración', route: '/configuracion' },
    ];

    return (
        <>
            <div className='w-100 p-4 bg-coparmex-blue topbar-coparmex'>
                <div className="row">
                    <div className="col-lg-3 col-md-5 col-sm-5">
                        <img className="coparmex-logo" src={Logo} alt="COPARMEX Logo" />
                    </div>
                    <div className="col-lg-6 col-md-2 col-sm-2">
                        {/* Add any content here if needed */}
                    </div>
                    <div className="col-lg-3 col-md-5 col-sm-5 text-end">
                        <p className='btn btn-lg btn-outline-secondary text-white border-0 lead pe-3 mt-1'>
                            <span>{luisa_username}<i className="bi bi-person-circle text-white ms-3"></i></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className='w-100 px-4 py-2 bg-coparmex-light topbar-coparmex-2'>
                <div className="row justify-content-center">
                    {sections.map((section, index) => (
                        <div
                            key={index}
                            className={`col-lg-2 col-md-2 col-sm-3 mx-1 btn ${activeButton === section.route ? 'btn-light' : 'btn-outline-light border-0'
                                }`}
                            onClick={() => handleSection(section.route)}
                        >
                            <Link
                                to={section.route}
                                className="w-100 text-coparmex-primary"
                                style={{ textDecoration: 'none' }}
                            >
                                {section.name}
                            </Link>
                        </div>
                    ))}
                    <div className="col-lg-1 col-md-1 col-sm-2 mx-1 btn btn-danger w-80" onClick={handleLogout}>
                        <span className=" text-white">Salir</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopbarCoparmex;