import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import { Link, useNavigate } from 'react-router-dom';

import Logo from '../../assets/img/logo_luisa.png';
import loadingGif from '../../assets/img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/login.css';
import config from '../../config/config';

import ConfirmationModal from '../modals/ConfirmationModal';

const VerifyEmail = () => {
    const location = useLocation();  // Obtiene la ubicación actual para leer los parámetros de la URL
    const [message, setMessage] = useState('Verificando tu correo...');  // Estado inicial

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);  // Extrae parámetros de la URL
        const token = queryParams.get('token');

        if (token) {
            // Hacer la solicitud GET al backend para verificar el token
            axios.get(`${config.backendURL}/verify-email?token=${token}`)
                .then(response => {
                    setMessage('¡Correo verificado con éxito! Ahora puedes iniciar sesión.');
                })
                .catch(error => {
                    setMessage('Error al verificar el correo. El token puede haber expirado o ser inválido.');
                });
        } else {
            setMessage('Token de verificación no encontrado.');
        }
    }, [location.search]);

    return (
        <div className='flex-column align-items-center py-3 px-5'>
        <div className='row justify-content-center'>
            <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
                <img className="w-50 mt-5" src={Logo} alt="COPARMEX Logo" />
            </div>
        </div>
        <div className='row justify-content-center'>
            <div className='col-sm-12 col-md-4 col-lg-3 p-login'>
                <h1 className='fw-bolder text-white text-center mb-4 animate__animated animate__fadeInUp'>Verificación de correo</h1>
                <div className='w-100 p-0 animate__animated animate__fadeInUp'>
                    <p className="form-label text-white text-center">{message}</p> {/* Muestra el mensaje correspondiente */}
                    <button type="button" className="btn btn-dark w-100" onClick={() => window.location.href = '/login'}>
                        Regresar a inicio
                    </button>
                </div>
            </div>
        </div>
    </div>
    );
};

export default VerifyEmail;

