import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface TextAreaModalProps {
    show: boolean;
    onHide: () => void;
    titulo: String;
    text: string;
    onChange: (newText: string) => void;
}

const TextAreaModal: React.FC<TextAreaModalProps> = ({ show, onHide, titulo, text, onChange }) => {
    const [editedText, setEditedText] = useState<string>(text);
    useEffect(() => {
        setEditedText(text);
    }, [text]);

    const handleSave = () => {
        onChange(editedText);
    };
    return (
        <Modal show={show} onHide={() => {onHide(); handleSave();}}>
            <Modal.Header closeButton>
                <Modal.Title>Editar {titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea
                    value={editedText}
                    onChange={(e) => {setEditedText(e.target.value)}}
                    style={{
                        width: '100%',
                        height: '200px',
                    }}
                />
            </Modal.Body>
        </Modal>
    );
};

export default TextAreaModal;