import React, { useState, ChangeEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '../../assets/img/logo_luisa.png';
import loadingGif from '../../assets/img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../../assets/css/login.css';
import axios from 'axios';
import config from '../../config/config';

import ConfirmationModal from '../modals/ConfirmationModal';

function Login() {
  const navigate = useNavigate();
  const [rfc, setRfc] = useState('');
  const [rfcError, setRfcError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [isRegisteredLuisa, setIsRegisteredLuisa] = useState(false);
  const [isEmpresaVinculadaRegistro, setEmpresaVincualadaRegistro] = useState(false)

  const [userDataNombreCompleto, setUserDataNombreCompleto] = useState("");
  const [userDataCorreoElectronico, setUserDataCorreoElectronico] = useState("");
  const [userDataRazonSocial, setUserDataRazonSocial] = useState("");
  const [userDataTelefonoWhatsApp, setUserDataTelefonoWhatsApp] = useState("");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [confirmModalType, setConfirmModalType] = useState('✅');

  const [showPassword, setShowPassword] = useState(false);


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "nombreCompleto":
        setUserDataNombreCompleto(value);
        break;
      case "email":
        setUserDataCorreoElectronico(value);
        break;
      case "razonSocial":
        setUserDataRazonSocial(value);
        break;
      case "telefonoWhatsApp":
        setUserDataTelefonoWhatsApp(value);
        break;
      default:
        break;
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(false);

  };

  const checkUser = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      //let isValid = true;
      event.preventDefault();
      setIsLoading(true);

      const rfcRegex = /^([A-ZÑ&]{3})(\d{2})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([A-Z\d]{3})?$/;
      if (!rfcRegex.test(rfc)) {
        setRfcError(true);
        setIsLoading(false);
        return;
      } else {
        setRfcError(false);
      }

      const url = `${config.backendURL}/login_rfc`;
      const response = await axios.post(url, { rfc });
      if (response.data.isRegistered) {
        setIsRegistered(true); // Si está registrado, habilitar el campo contraseña
        if (response.data.isRegisteredLuisa) {
          setIsLoading(false);
          setIsRegisteredLuisa(true);
          setConfirmModalType('✅');
          setModalMessage('El RFC ingresado es válido, escribe tu contraseña para ingresar.');
          setIsModalOpen(true);
        } else {
          setIsLoading(false);
          setConfirmModalType('✅');
          setModalMessage('La empresa esta vinculada, por favor completa tu información para el sistema Luisa.');
          setEmpresaVincualadaRegistro(true)
          setIsModalOpen(true);

          //Set User Data from db
          setUserDataNombreCompleto(response.data.user_rfc.contactos[0].nombre + " " + response.data.user_rfc.contactos[0].apellido_paterno + " " + response.data.user_rfc.contactos[0].apellido_materno);
          setUserDataCorreoElectronico(response.data.user_rfc.contactos[0].email);
          setUserDataRazonSocial(response.data.user_rfc.razon_social);
          setUserDataTelefonoWhatsApp(response.data.user_rfc.contactos[0].celular);

          console.log(response.data);
        }
      } else {
        setIsLoading(false);
        setConfirmModalType('❌');
        setModalMessage('El RFC no esta vinculado en COPARMEX, si requiere vincularse, favor de enviar un whatsapp a Luisa al numero +52 5541848739.');
        setIsModalOpen(true);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setConfirmModalType('❌');
      setModalMessage('Hubo un error en la solicitud, intentelo más tarde');
      setIsModalOpen(true);
    }
  };

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()[\]{}|\\/_\-+=.,;:'"`~<>])[A-Za-z\d!@#$%^&*()[\]{}|\\/_\-+=.,;:'"`~<>]{10,}$/;
    return passwordRegex.test(password);
  };

  const handleRfcChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRfc(event.target.value);
    setRfcError(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isValid = true;
    setIsLoading(true);

    const rfcRegex = /^([A-ZÑ&]{3})(\d{2})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([A-Z\d]{3})?$/;
    if (!rfcRegex.test(rfc)) {
      setRfcError(true);
      isValid = false;
    } else {
      setRfcError(false);
    }

    if (!validatePassword(password)) {
      setPasswordError(true);
      isValid = false;
    }

    if (!isValid) {
      setIsLoading(false);
      return;
    }
    if (isRegisteredLuisa) {
      await loginUser();
    } else { //logica para hacer el registro
      await registerUser();
    }

  };

  async function registerUser() {
    try {
      const response = await axios.post(`${config.backendURL}/register`, {
        rfc,
        password,
        extra_data: {
          userDataNombreCompleto: userDataNombreCompleto,
          userDataCorreoElectronico: userDataCorreoElectronico,
          userDataRazonSocial: userDataRazonSocial,
          userDataTelefonoWhatsApp: userDataTelefonoWhatsApp
        }
      });
      console.log(`Valor de la respuesta del server: ${response.status}`);
      if (response.status === 201) {
        // Store session stuff
        const storeItems = [
          localStorage.setItem('luisa_token', response.data.token),
          localStorage.setItem('luisa_whatsapp_number', response.data.phoneNumber),
          localStorage.setItem('luisa_username', response.data.username),
          localStorage.setItem('luisa_rfc', response.data.rfc),
          localStorage.setItem('luisa_business_name', response.data.business_name),
          localStorage.setItem('luisa_email', response.data.email),
          localStorage.setItem('luisa_temporal_account', response.data.email),

        ];
        await Promise.all(storeItems);
        setConfirmModalType('✅');
        setModalMessage(`Se te enviará un link por el correo electrónico (${response.data.email}) proporcionado para finalizar el registro`);
        setIsModalOpen(true);
        //navigate('/perfil_vinculacion');
        navigate('/');
      } else {
        setConfirmModalType('❌');
        setModalMessage('El RFC es incorrecto por favor verificar que sea uno dado de alta en COPARMEX.');
        setIsModalOpen(true);
      }
    } catch (error: any) {
      console.log(error);
      setConfirmModalType('❌');
      setModalMessage('Nos encontramos con un problema interno, favor de intentarlo más tarde');
      setIsModalOpen(true);
      console.error('Error al registrar el usuario', error);
    } finally {
      //setShowDiscountModal(false);
      //setLoadingCoupon(false);
    }
  };


  async function loginUser() {
    try {
      const response = await axios.post(`${config.backendURL}/login`, {
        //username: username,
        rfc: rfc,
        password: password
      });

      if (response.status === 201) {
        // Store session stuff
        //modify to save the info for luisa

        localStorage.setItem('luisa_token', response.data.token);
        localStorage.setItem('luisa_whatsapp_number', response.data.whatsapp_number);
        localStorage.setItem('luisa_username', response.data.username);
        localStorage.setItem('luisa_business_id', response.data.businessId);
        localStorage.setItem('luisa_email', response.data.email);
        localStorage.setItem('luisa_temporal_account', response.data.user_temporal_account);
        localStorage.setItem('luisa_rfc', response.data.rfc);

        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('luisa_token')}`;
        setIsLoading(false);
        window.location.reload();
        //navigate('/perfil_vinculacion');
        //cerrar primer then
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // El error es de Axios, por lo tanto tiene la propiedad response
        if (error.response && error.response.status === 401) {
          console.log(error);
          setIsLoading(false);
          setConfirmModalType('❌');
          setModalMessage('Datos incorrectos, favor de verificarlos.');
          setIsModalOpen(true);
        }
      } else {
        console.log(error);
        setIsLoading(false);
        setConfirmModalType('❌');
        setModalMessage('Nos encontramos con un problema interno, favor de intentarlo más tarde');
        setIsModalOpen(true);
      }
      console.log(error);
      setIsLoading(false);
      setConfirmModalType('❌');
      setModalMessage('Nos encontramos con un problema interno, favor de intentarlo más tarde');
      setIsModalOpen(true);
    }
  }

  return (
    <div className='flex-column align-items-center py-3 px-5 bg-login-animation'>
      {<ConfirmationModal
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
        }}
        text={modalMessage}
        type={confirmModalType}
        titleText={isRegisteredLuisa ? 'Login' : 'Registro'}
      />}
      <div className='row justify-content-center'>
        <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
          <img className="w-80 mt-5" src={Logo} alt="COPARMEX Logo" />
        </div>
      </div>

      <div className='row justify-content-center'>
        <div className="col-sm-12 col-md-8 col-lg-8 p-login">
          {isRegistered ? (
            isEmpresaVinculadaRegistro ? (
              <h1 className='fw-bolder text-white text-center mb-3 animate__animated animate__fadeInUp'>
                Confirma tus datos
              </h1>
            ) :
              <h1 className='fw-bolder text-white text-center mb-3 animate__animated animate__fadeInUp'>
                Iniciar Sesión
              </h1>
          ) : (
            <h1 className='fw-bolder text-white text-center mb-3 animate__animated animate__fadeInUp'>
              Iniciar Sesión
            </h1>
          )}
          <form noValidate className={`${isEmpresaVinculadaRegistro ? 'bg-transparent-coparmex' : ''} p-5 rounded w-100 p-0 animate__animated animate__fadeInUp`} onSubmit={isRegistered ? handleSubmit : checkUser}>

            <div className={`row ${!isEmpresaVinculadaRegistro ? 'justify-content-center' : ''}`}>
              {/*------------------- COL 1 -------------------*/}
              <div className='col-sm-6 px-4'>
                <div className={`mb-${isEmpresaVinculadaRegistro ? '3' : '0'}`}>
                  {isEmpresaVinculadaRegistro && (
                    <label className='text-secondary-2 w-100 mb-1'>RFC</label>
                  )}
                  <input
                    type="text"
                    className={`form-control form-control-lg bg-coparmex-login centered-placeholder centered-input-container d-flex ${rfcError ? 'is-invalid' : ''}`}
                    placeholder='RFC del usuario'
                    onChange={handleRfcChange}
                    autoComplete="off"
                    value={rfc}
                    disabled={(isEmpresaVinculadaRegistro || isRegisteredLuisa) ? true : false}
                  />
                  {rfcError && <div className="invalid-feedback">Por favor, introduce un RFC válido.</div>}
                </div>

                {isEmpresaVinculadaRegistro && (
                  <div>
                    <div className="mb-3">
                      <label className='text-secondary-2 w-100 mb-1'>Nombre Completo</label>
                      <input
                        type="text"
                        name="nombreCompleto"
                        className={`form-control form-control-lg bg-coparmex-login centered-placeholder centered-input-container d-flex`}
                        placeholder='Nombre Completo'
                        onChange={handleChange}
                        autoComplete="off"
                        value={userDataNombreCompleto}
                      />
                      {rfcError && <div className="invalid-feedback">Por favor, introduce un RFC válido.</div>}
                    </div>

                    <div className="mb-3">
                      <label className='text-secondary-2 w-100 mb-1'>Correo Electrónico</label>
                      <input
                        type="text"
                        name="email"
                        className={`form-control form-control-lg bg-coparmex-login centered-placeholder centered-input-container d-flex`}
                        placeholder='Correo Electrónico'
                        onChange={handleChange}
                        autoComplete="off"
                        value={userDataCorreoElectronico}
                      />
                      {rfcError && <div className="invalid-feedback">Por favor, introduce un RFC válido.</div>}
                    </div>
                  </div>

                )}
              </div>

              {/*------------------- COL 2 -------------------*/}

              <div className={`${isEmpresaVinculadaRegistro ? 'col-sm-6 px-4' : 'row justify-content-center'}`}>

                {isEmpresaVinculadaRegistro && (
                  <div className="mb-3">
                    <label className='text-secondary-2 w-100 mb-1'>Razón Social</label>
                    <input
                      type="text"
                      name="razonSocial"
                      className={`form-control form-control-lg bg-coparmex-login centered-placeholder centered-input-container d-flex`}
                      placeholder='Razón Social'
                      onChange={handleChange}
                      autoComplete="off"
                      value={userDataRazonSocial}
                    />
                    {rfcError && <div className="invalid-feedback">Por favor, introduce un RFC válido.</div>}
                  </div>
                )}
                {isEmpresaVinculadaRegistro && (
                  <div className="mb-3">
                    <label className='text-secondary-2 w-100 mb-1'>Teléfono WhatsApp</label>
                    <input
                      type="text"
                      name="telefonoWhatsApp"
                      className={`form-control form-control-lg bg-coparmex-login centered-placeholder centered-input-container d-flex`}
                      placeholder='Teléfono WhatsApp'
                      onChange={handleChange}
                      autoComplete="off"
                      value={userDataTelefonoWhatsApp}
                    />
                    {rfcError && <div className="invalid-feedback">Por favor, introduce un RFC válido.</div>}
                  </div>
                )}

                {(isEmpresaVinculadaRegistro || isRegisteredLuisa) && (

                  <div className={`${isRegisteredLuisa ? 'col-sm-6 px-4' : ''}`}>

                    <div className="mb-3 position-relative" >
                      <label className='text-secondary-2 w-100 mb-1'>{`${isEmpresaVinculadaRegistro ? 'Contraseña' : ''}`}</label>
                      <div className="input-group">
                        {isRegistered && (
                          <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control form-control-lg bg-coparmex-login centered-placeholder centered-input-container d-flex ${passwordError ? 'is-invalid' : ''}`}
                            placeholder='Contraseña'
                            onChange={handlePasswordChange}
                            autoComplete="off"
                          />
                        )}

                        {isRegistered && (
                          <span className="input-group-text bg-coparmex-login" onClick={handlePasswordVisibility} style={{ cursor: 'pointer' }}>
                            <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                          </span>
                        )}

                      </div>
                      {passwordError && <div className="invalid-feedback" style={{ display: 'block' }}>La contraseña debe tener al menos 10 caracteres con al menos una mayuscula, minuscula, numero y carácter especial.</div>}
                    </div>
                  </div>

                )}
              </div>

            </div>


            {/*------------------- COL 3 -------------------*/}
            <div className="row justify-content-center mt-4">
              <div className='col-sm-6 px-4'>

                <button type="submit" 
                className="btn btn-primary btn-lg w-100 mt-2" 
                disabled={isLoading || !(rfc && password && userDataNombreCompleto && userDataCorreoElectronico && userDataRazonSocial && userDataTelefonoWhatsApp || !isRegistered || isRegisteredLuisa)}
                >
                  {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Continuar'}
                </button>

                {loginError && <div className="alert alert-danger mt-3">{loginError}</div>}

                {!isRegistered && (
                  <div>
                    <div className='text-center mt-4'>
                      <span className='fs-6 text-white'>¿Olvidaste tu contraseña? <Link to="/forgot_password" className="text-primary">Cambiar Contraseña</Link></span>
                    </div>
                    <div className='text-center mt-2'>
                      <span className='fs-6 text-white'>¿Aún no eres socio coparmex? <Link to="/forgot_password" className="text-primary">Únete</Link></span>
                    </div>
                  </div>

                )}


              </div>
            </div>








          </form>
        </div>
      </div>

    </div >
  );
}

export default Login;
