import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './assets/css/vinculacion.css';
import imgProfile from './assets/img/luisa_profile.png';
import { Linkups } from './components/interfaces/LinkupInterface';
import LoadFilesModal from './components/modals/LoadFilesModal';
import { Files } from './components/interfaces/KnowledgeInterface';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopbarCoparmex from './components/main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';

const Vinculacion: React.FC = () => {
    const [activeTab, setActiveTab] = useState('Vinculación');
    const [businessName, setBusinessName] = useState('');
    const [businessDescription, setBusinessDescription] = useState('');
    const [businessTags, setBusinessTags] = useState<string[]>([]);
    const [businessLinkups, setBusinessLinkups] = useState<Linkups[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadFiles, setLoadFiles] = useState<Files[]>([]);
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    //MODALS
    const [isFilesModal, setFilesModal] = useState(false);


    const handleTabChange = (tab: React.SetStateAction<string>) => {
        setActiveTab(tab);
    };

    const handleLinkupUpgrade = () => {
        setFilesModal(true);
    }

    const handleNewLinkup = () => {
        navigate('/perfil_vinculacion');
    }

    const showNotification = () => {
        toast('📌 Recuerda completar tu perfil para poder aprovechar al máximo tus vinculaciones!', {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        const storedUsername = localStorage.getItem('luisa_username');
        if (storedUsername) {
            setUsername(storedUsername);
        }
        const fetchLinkup = async () => {
            try {
                //const response = await axios.post(`http://localhost:3333/backend/getLinkups`, {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getLinkups`, {
                    business_id: '5215580177109'
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NmY0ZGZkMDY3N2I5MGQ4NzY5MWQ0YTQiLCJpYXQiOjE3MjczMjY0MDYsImV4cCI6MTcyNzkzMTIwNn0.-tXtV_6yHkPwH9BhgLzpQ7Quvo4Tn20A_NAWhgGT8k4`
                            //'Authorization': `Bearer ${gojimx_token}`
                        }
                    });
                if (response.status === 200) {
                    const linkupData = response.data;
                    setBusinessName(linkupData.business_parameters.business_name);
                    setBusinessDescription(linkupData.business_parameters.business_slogan);
                    setBusinessTags(linkupData.linkup_parameters.filters);
                    setBusinessLinkups([linkupData.linkup_parameters]);
                }

            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        console.log(getError);

                    } else if (error.request) {
                        console.log('Request made but no response received:', error.request);
                    } else {
                        console.log('Error message:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            }
        };
        showNotification();
        fetchLinkup();
    }, []);

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <div className='flex-column align-items-center py-3 px-5 linkup-bg mt-5 '>
                {/* <ToastContainer /> */}
                <div className='row justify-content-center p-linkup'>

                    <div className="col-lg-7 col-md-7 col-sm-7">
                        {/* <ul className="tabs nav ">
                                <li className="tab-link nav-pill mt-2">
                                    <button
                                        className={activeTab === "Vinculación" ? 'btn btn-blue border-0 rounded-1 px-4 pt-0 pb-1 me-2' : 'btn btn-dark border-0 rounded-1 px-4 pt-0 pb-1 me-2'}
                                        onClick={() => {
                                            handleTabChange('Vinculación');
                                        }}
                                    >Vinculación</button>
                                </li>
                                <li className="tab-link nav-pill mt-2">
                                    <button disabled
                                        className={activeTab === "Publicidad" ? 'btn btn-blue border-0 rounded-1 px-4 pt-0 pb-1 me-2' : 'btn btn-dark border-0 rounded-1 px-4 pt-0 pb-1 me-2'}
                                        onClick={() => {
                                            handleTabChange('Publicidad');
                                        }}
                                    >Publicidad</button>
                                </li>
                                <li className="tab-link nav-pill mt-2">
                                    <button disabled
                                        className={activeTab === "Eventos" ? 'btn btn-blue border-0 rounded-1 px-4 pt-0 pb-1 me-2' : 'btn btn-dark border-0 rounded-1 px-4 pt-0 pb-1 me-2'}
                                        onClick={() => {
                                            handleTabChange('Eventos');
                                        }}
                                    >Eventos</button>
                                </li>
                                <li className="tab-link nav-pill mt-2">
                                    <button disabled
                                        className={activeTab === "" ? 'btn btn-blue border-0 rounded-1 px-4 pt-0 pb-1 me-2' : 'btn btn-dark border-0 rounded-1 px-4 pt-0 pb-1 me-2'}
                                        onClick={() => {
                                            handleTabChange('');
                                        }}
                                    >Luisa</button>
                                </li>
                                <li className="tab-link nav-pill mt-2 me-2">
                                    <button disabled
                                        className={activeTab === "" ? 'btn btn-blue border-0 rounded-1 px-4 pt-0 pb-1 me-2' : 'btn btn-dark border-0 rounded-1 px-4 pt-0 pb-1 me-2'}
                                        onClick={() => {
                                            handleTabChange('');
                                        }}
                                    >Configuración</button>
                                </li>
                                <li className="tab-link mt-2 ms-5">
                                    <button
                                        className='btn btn-danger border-0 rounded-1 px-4 pt-0 pb-1 me-2'
                                        onClick={handleLogout}
                                    >Salir</button>
                                </li>
                            </ul> */}

                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>Vinculaciones</h1>

                        <div className="row">
                            <div className="col-6">
                                <button className='btn btn-linkup px-3' onClick={handleNewLinkup}>
                                    <i className="bi bi-box-arrow-up pe-2"></i>
                                    GENERAR NUEVA VINCULACIÓN
                                </button>
                            </div>
                            <div className="col-6">
                                <button className='btn btn-linkup'>
                                    <i className="bi bi-file-text pe-2"></i>
                                    GENERAR INFORME TRIMESTRAL
                                </button>
                            </div>
                        </div>

                        <table className='table table-striped-columns mt-4'>
                            <thead>
                                <th>Nombre de la vinculación</th>
                                <th>Fecha</th>
                                <th>Tipo</th>
                                <th>Categorias</th>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Vinculacion;
