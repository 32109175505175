import React from 'react';

const TerminosCondiciones: React.FC = () => {
    return (
        <div className="terms-container p-3 text-light">
            <h1 className='mb-4'>Terminos y Condiciones</h1>
            <h2>1. Terminos</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h2>2. Condiciones</h2>
            <p>
                Lobortis justo ad sagittis curae semper platea aenean litora facilisis donec vehicula, class aliquet cursus eu per urna primis fusce eget tristique. Viverra litora potenti quis donec dapibus cum cubilia pharetra, ridiculus mus phasellus neque dictum tellus velit primis mattis, hendrerit nostra senectus mollis dis tincidunt nunc. Nec dignissim nisl per nisi ante posuere dictumst bibendum sociosqu, feugiat ad torquent sed enim dui tincidunt massa
            </p>
        </div>
    );
};

export default TerminosCondiciones;
