import React, { useState, ChangeEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '../../assets/img/logo_luisa.png';
import loadingGif from '../../assets/img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/login.css';
import axios from 'axios';
import config from '../../config/config';

import ConfirmationModal from '../modals/ConfirmationModal';

function Change_password(){
    const [codeNumber, setCodeNumber] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [isValidCode, setIsValidCode] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false); 

    const [isLoading, setIsLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [confirmModalType, setConfirmModalType] = useState('✅');

    const navigate = useNavigate();

    const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
       
        if (/^\d*$/.test(value) && value.length <= 5) {
            setCodeNumber(value);
            setCodeError(false);
        }

        setIsValidCode(value.length >= 5);
    };

    const validatePassword = (password:string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()[\]{}|\\/_\-+=.,;:'"`~<>])[A-Za-z\d!@#$%^&*()[\]{}|\\/_\-+=.,;:'"`~<>]{10,}$/;
        return passwordRegex.test(password);
      };

    const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setPasswordError(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) =>{
        event.preventDefault();
        let isValid = true;
        setIsLoading(true);


        if (!isValidCode) {
            setCodeError(true);
            isValid = false;
          }

        if (!validatePassword(password)) {
            setPasswordError(true);
            isValid = false;
        }

        if (!isValid) {
            setIsLoading(false);
            return;
        }
        try{
            console.log("Intenta cambiar la contraseña");
            const response = await axios.post(`${config.backendURL}/change_password`, {
              code: codeNumber,
              new_password: password
            });
            console.log(`Valor de la respuesta del server: ${response.status}`);
            if (response.status === 201) {
                //enviar a home o poner un anuncio que ya se envio un mensaje
                setIsLoading(false);
                setConfirmModalType('✅');
                setModalMessage(`Se ha cambiado la contraseña, puede intentar ingresar con esta nueva contraseña`);
                setIsModalOpen(true);
                navigate('/login');
                //window.location.reload();
            }
        }catch (error: unknown) {
            if (axios.isAxiosError(error)) {
              // El error es de Axios, por lo tanto tiene la propiedad response
              if (error.response && error.response.status === 401) {
                console.log(error);
                setIsLoading(false);
                setConfirmModalType('❌');
                setModalMessage('Código invalido, verifique el código o solicite otro.');
                setIsModalOpen(true);
              }
            } else {
              console.log(error);
              setIsLoading(false);
              setConfirmModalType('❌');
              setModalMessage('Nos encontramos con un problema interno, favor de intentarlo más tarde');
              setIsModalOpen(true);
            }
            console.log(error);
            setIsLoading(false);
            setConfirmModalType('❌');
            setModalMessage('Nos encontramos con un problema interno, favor de intentarlo más tarde');
            setIsModalOpen(true);
          }
       
         

    };

    return(
    <>
        <div className='flex-column align-items-center py-3 px-5 bg-login-animation'>
            {<ConfirmationModal
                show={isModalOpen}
                onHide={() => {
                setIsModalOpen(false);
                navigate('/login');
                }}
                text={modalMessage}
                type={confirmModalType}
                titleText='Cambiar Contraseña'
            />}
            <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
                    <img className="w-50 mt-5" src={Logo} alt="COPARMEX Logo" />
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-4 col-lg-3 p-login'>
                    <h1 className='fw-bolder text-white text-center mb-4 animate__animated animate__fadeInUp'>Cambiar Contraseña</h1>
                    <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input
                                type="text"
                                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${codeError ? 'is-invalid' : ''}`}
                                placeholder='Código enviado por mensaje'
                                onChange={handleCodeChange}
                                autoComplete="off"
                                value={codeNumber}
                            />
                            {codeError && <div className="invalid-feedback">Por favor, introduce un código valido de 5 dígitos.</div>}
                        </div>
                        <div className="mb-3 position-relative" >
                            <div className="input-group">
                                <input
                                type={showPassword ? "text" : "password"} 
                                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${passwordError ? 'is-invalid' : ''}`}
                                placeholder='Contraseña'
                                onChange={handlePasswordChange}
                                autoComplete="off"
                                />
                                <span className="input-group-text" onClick={handlePasswordVisibility} style={{ cursor: 'pointer' }}>
                                <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                </span>
                            </div>     
                            {passwordError && <div className="invalid-feedback" style={{ display: 'block' }}>La contraseña debe tener al menos 10 caracteres con al menos una mayuscula, minuscula, numero y carácter especial.</div>}
                            </div>
                        <button type="submit" className="btn btn-dark w-100" disabled={isLoading}>
                            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Cambiar contraseña'}
                        </button>
                        
                     </form>
                </div>
            </div>
            
        </div>
    </>
    )
}

export default Change_password;